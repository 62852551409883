import Header from "../../components/Header/index";
import "./index.css";
import { Footer } from "../../components/Footer/Footer";
import cron from "../../assets/images/fulleclipse.svg";
import Community from "../../components/PageComponents/Community";
import Ribbon from "../../components/PageComponents/Ribbon-Area";
function Communities() {
  return (
    <div className="hero-container">
      <Header title="Grow And Network With Primes" sub=""  headerStyle="half-bg"/>
      <Community dark />
      <div className="community-top">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="primeX-area">
              <h4 className=" pt-3 menu-text">Community benefits</h4>
              <div className="d-flex">
                <div className="thin-menu-line-comm"></div>
                <div className="thick-menu-line-comm"></div>
              </div>
              <br />
              <div className="font-texts">
                <div>
                  <b>Become a priority everywhere : </b>Get treated as a VIP at every spot you book a reservation for on Prime X
                </div>{" "}
                <br />
                <div>
                  <b>Explore lucury based alternatives :</b> Find luxury based activities around you and within your budget
                </div>{" "}
                <br />
                <div>
                  <b>Maximim value for your money: </b>
                  Enjoy distinct services that matches and surpasses every kobo spent
                </div>{" "}
                <br />
                <div>
                  <b>Secure & hassle-free payments: </b>
                  pay for every service even before demand with your Prime X wallet
                </div>
                <br />
                <div>
                  <b> Connect with other HNIs : </b> Meet new HNIs in the community and when one is around you offline
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mt-5">
            <div className="d-flex justify-content-center align-items-center">
              <img src={cron} className="w-75" />
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <Ribbon />
      <br />
      <br />

      <br />
      <Footer />
    </div>
  );
}

export default Communities;
