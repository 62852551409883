import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "../views/Landing/index";
import About from "../views/About/index";
import Contact from "../views/Contact/index";
import Community from "../views/Communnity/index";
import Policy from "../views/Privacy/policy";
import "../App.css"


export default function AppRouter(props) {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/about" exact component={About} />
        <Route path="/community" exact component={Community} />
        <Route path="/contact-us" exact component={Contact} />
        <Route path="/privacy-policy" exact component={Policy} />

      </Switch>
    </Router>
  );
}
