import bell from "../../assets/images/Toa.png";
import React, {useState} from "react";
import { Button } from "../Button/index";
import { Input } from "../Input/index";
import { notification } from "antd";
export default function Community({ dark }) {
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const send = (e) => {
    e.preventDefault();
    if (email) {
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
        Notification(
          "success",
          "Success",
          "Your email has been recieved successfully!"
        );
      },1800)
     
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
    <div className="pb-5 pt-5">
      <div className={dark ? "prime-community-dark" : "prime-community"}>
        <div className="text-center mb-4">
          <div className={ dark ? "text-white hero-medium-text  text-center pt-4" : "hero-medium-text  text-center pt-4" }>
            The prime Community
          </div>{" "}
          <br />
          <div className="medium-text-dark text-center ">
            We are creating a community for high net-worth individuals. This
            community will bring
          </div>
          <div className="medium-text-dark text-center">
            {" "}
            in networking, connections, business, e.t.c.
          </div>
        </div>
        <br />
        <br />
        <div className="d-flex d-flex justify-content-center align-items-center">
          <Input
            placeholder="Enter your email address"
            className="communnity-input"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <img src={bell} className="bell-community" />
        </div>
        <div className="community-btn">
          <Button
            text={load ? "Sending..." : "Join the waiting list"}
            onClick={send}
            style={{
              background: dark ? "white" : "#030389",
              color: dark ? "#030389" : "white",
            }}
          />{" "}
        </div>
      </div>
    </div>
  );
}
