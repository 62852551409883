import { useState } from "react";
import "./index.css";
import logo from "../../assets/images/logo.svg";
import fb from "../../assets/images/fb.svg";
import twitter from "../../assets/images/twitter.svg";
import instagram from "../../assets/images/insta.svg";
import diamond from "../../assets/images/diamond.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer>
      <div className="mt-5 pt-3">
        <div className="row">
          <div className="col-lg-3 col-sm-6 to-center mb-5">
            <Link to="/">
              <img src={logo} width="50" />
            </Link>
          </div>
          <div className="col-lg-9">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-6 mb-2">
                  <p className="footer-title mb-3">COMPANY</p>
                  <Link to="/about" className="footer-sub-text my-4" >
                    About Us
                  </Link>
                
                  <Link to="/privacy-policy" className="footer-sub-text my-4" href="/privacy-policy">
                    Privacy Policy
                  </Link>
                  <Link to="/terms" className="footer-sub-text my-4">
                    Terms & Conditions
                  </Link>
                </div>
                <div className="col-lg-3  col-6 mb-2">
                  <p className="footer-title mb-3">PRODUCTS</p>
                  <a href="#" className="footer-sub-text my-4" href="/support">
                    Prime.x
                  </a>
                  <a href="#"  className="footer-sub-text my-4">
                    Merchants
                  </a>
                
                </div>
                <div className="col-lg-3  col-6 mb-2">
                  <p className="footer-title mb-3">COMMUNITY</p>
                  <a href="#"  className="footer-sub-text my-4" href="/support">
                    Prime Verse
                  </a>
                 
                </div>
                <div className="col-lg-3  col-6 mb-2">
                  <p className="footer-title mb-3">CONTACTS</p>
                  <a href="#"  className="footer-sub-text my-4" href="/support">
                    Lagos, Nigeria
                  </a>
                 
                  
                  <a href="#"  className="footer-sub-text my-4" href="/terms&conditions">
                    info@hniprime.com
                  </a>
                  <div className="d-flex justify-content align-items-center">
                    <img src={instagram} className="pr-3" />
                    <img src={fb} className="pr-3" />
                    <img src={twitter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center pt-5 pb-5 mb-4 copy-rite">
          <span> © Copyright Hniprime {new Date().getFullYear()} </span>
        </div>
      </div>
    </footer>
  );
};
