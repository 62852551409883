import logo from './logo.svg';
import './App.css';
import AppRouter from './routes/routes'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'


function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
