import Header from "../../components/Header/index";
import "./index.css";
import { Footer } from "../../components/Footer/Footer";
import cron from "../../assets/images/cron.svg";
import cron2 from "../../assets/images/cron2.png";
import person from "../../assets/images/person.png";
import person2 from "../../assets/images/first-sqaure.png";
import Community from "../../components/PageComponents/Community";
import React, { useEffect, useState } from "react";
function About() {
  const [stage1, setStage1] = useState(true);
  const [stage2, setStage2] = useState(false);
  const [stage3, setStage3] = useState(false);

  const gotoStage1 = () => {
    setStage1(true);
    setStage2(false);
    setStage3(false);
  };

  const gotoStage2 = () => {
    setStage1(false);
    setStage2(true);
    setStage3(false);
  };

  const gotoStage3 = () => {
    setStage1(false);
    setStage2(false);
    setStage3(true);
  };

  return (
    <div className="hero-container">
      <Header
        title="Maximum value in Luxury Currency"
        sub=""
        headerStyle="half-bg"
      />
      <div className="about-page-top">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="primeX-area">
              <h4 className=" pt-3 menu-text">Our Story</h4>
              <div className="d-flex">
                <div className="thin-menu-line"></div>
                <div className="thick-menu-line"></div>
              </div>
              <br />

              <div className="font-texts">
                <div>
                  It started with a love for luxury until it turned out to be
                  more - the desire to allow everyone to get the VIP experience
                  of services regardless of their social status.
                </div>{" "}
                <div>
                  In a time where there is almost no genuine value for money
                  spent, there is a need for a community that allows everyone on
                  all financial levels to experience comfort and luxury even
                  with their smallest activity.
                </div>{" "}
                <div>
                  {" "}
                  The impact of the Covid-19 pandemic further pushed for the
                  invention of a technological solution that can combat the
                  mayhem unleashed on the job market within and without Nigeria.{" "}
                </div>{" "}
                <br />
                <div>
                  Thus, HNI Prime was birthed; a solution that addresses and
                  will simplify daily life challenges even in the future.
                </div>
                <br />
                <div>
                  Our touch of ‘extra’ on the simplest activities in life makes
                  us even more unique. With solutions available on the go that
                  allows you access to your services in style, we are creating a
                  go big or go home mentality for individuals who need it while
                  allowing them to explore other luxury-based services that are
                  reasonably affordable. From requesting a service, to how the
                  request and payments are made, the Prime X solution ensures
                  all its users get the VIP treatment from day one.
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 pt-5">
            <div className="d-flex justify-content-center align-items-center">
              <img src={cron} className="w-75" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 pt-5">
        <div>
          <div className="fore-dark-border-bg">
            <div className="row">
              <div className="col-md-6 col-12 ">
                <div className="d-flex ">
                  <div className="mr-5">
                    <div className="d-flex align-items-center flex-column">
                      <div
                        className={`mb-5 ${
                          stage1
                            ? "vertical-carousel-active"
                            : "vertical-carousel"
                        }`}
                        onClick={gotoStage1}
                      ></div>
                      <div
                        className={`mb-5 ${
                          stage2
                            ? "vertical-carousel-active"
                            : "vertical-carousel"
                        }`}
                        onClick={gotoStage2}
                      ></div>
                      <div
                        className={`mb-5 ${
                          stage3
                            ? "vertical-carousel-active"
                            : "vertical-carousel"
                        }`}
                        onClick={gotoStage3}
                      ></div>
                    </div>
                  </div>
                  {stage1 && (
                    <div>
                      <div
                        className="orange-text-medium"
                        style={{ fontWeight: "300" }}
                      >
                        Our mission
                      </div>
                      <h3 className="pt-4 text-white font-weight-bold">
                        Building a community <br />
                        that understands value
                      </h3>
                      <br />
                      <div className="text-white" style={{ opacity: 1 }}>
                        <div>
                          The first was to convince my husband of the merits of
                          all - natural deodorant, including the kind that
                          hasn't been processed with formaldehyde.{" "}
                        </div>{" "}
                        <div>
                          So I went into a Mary Kay meeting with a list of
                          deodorants and asked for everyone's opinion.
                        </div>{" "}
                        <div>
                          {" "}
                          Then I discussed my options with my husband and some
                          friends. I ultimately chose the original version -
                          there are also many versions in different scents,
                          oils, and a couple of powders.
                        </div>
                        <br />{" "}
                        <div>
                          {" "}
                          I liked the weight of the paste and the scent was
                          fresh and different from other brands that I have
                          tried.
                        </div>{" "}
                        <br />
                        <div>
                          {" "}
                          After using it for about a week and experiencing no
                          problem with breakouts (thankfully), I realized that
                          it has a certain mental appeal.{" "}
                        </div>
                        <br />{" "}
                        <div>
                          {" "}
                          After trying it, I realized that I had been
                          accidentally using more of it than I realized.
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-12 hide-mobile">
                {/* <div class="outer circle-concentric">
                  <div class="middle circle-concentric">
                    <div class="inner circle-concentric"></div>
                  </div>
                </div> */}
                <div
                  className="d-flex justify-content-center align-items-start"
                  style={{ height: "100%" }}
                >
                  <img src={person} width="200" />
                  <div className="below-pic">
                    <img src={person2} width="200" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="pt-5 pb-5">
        <Community />
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default About;
